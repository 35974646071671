/* eslint-disable react/jsx-boolean-value */
import _ from 'lodash'
import React, { useState, useEffect, useMemo } from 'react'
import moment from 'moment/min/moment-with-locales'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs'
import NumberFormat from 'react-number-format'
import { orderMonths, convertItemsToSectionList, parseLoyaltyPoints } from '../../../../helpers'
import useStyles from './tabs-style'
import {
  CouponMonthBlock, CouponCard, MessageScreen, Spinner,
} from '../../../../ui'

const CouponsTabs = (props) => {
  const {
    loyaltyPointExpiry,
    hasMorLoyaltyPointExpiry,
    hasMorLoyaltyPointHistory,
    hideLoyaltyPoints,
    isLoyaltyPointExpiryReady,
    isLoyaltyPointHistoryReady,
    loyaltyPointHistory,
    onFetchLoyaltyPointExpiryNextPage,
    onFetchLoyaltyPointHistoryNextPage,
  } = props
  const { t } = useTranslation()
  const style = useStyles()
  const [pointsOrdByMonths, setPointsOrdByMonths] = useState([])

  const dateFormat = t('ui.dateFormat.month', { defaultValue: 'MMMM YYYY' })

  const pointsExpiryOrdByMonths = useMemo(() => (
    convertItemsToSectionList({
      items: loyaltyPointExpiry,
      sectionTitle: (item) => {
        const date = _.get(item, 'expireAt');
        return _.isNil(date)
          ? t('screens.account.loyaltyPoints.sectionTitle.noExpireDate')
          : moment(date).format(dateFormat)
      },
    })
  ), [loyaltyPointExpiry])

  function getLoyaltyPointToItemData(data = {}) {
    const {
      activeAt,
      activeAtLabel,
      basePoints,
      codeLabel,
      couponToken,
      expireAt,
      points,
      unspentPoints,
    } = data
    const displayTime = `${moment(activeAt).format('DD.MM.YYYY HH:mm')}`
    const pointExpiryNotes = !_.isNil(expireAt)
      && t(
        'screens.account.coupons.loyaltyPointTransactions.expireAt',
        {
          date: moment(expireAt).format(
            t(
              'screens.account.coupons.loyaltyPointTransactions.expireAtDateFormat',
              { defaultValue: 'DD.MM.YYYY' },
            ),
          ),
          points,
          basePoints,
          unspentPoints,
        },
      )

    let state = ''
    let pointSign = ''
    switch (true) {
      case points > 0:
        state = 'success'
        pointSign = 'positive'
        break
      case points < 0:
        state = 'error'
        pointSign = 'negative'
        break
      case points === 0:
      default:
        state = 'disabled'
        break
    }
    const isTransferable = !_.isEmpty(
      _.intersection(
        _.get(couponToken, 'canActions', []),
        ['transfer_out', 'transfer_in', 'transfer_undo'],
      ),
    ) && _.get(couponToken, 'transferState') !== 'completed'
    return {
      title: _.get(couponToken, 'coupon.name', codeLabel),
      ...(
        pointExpiryNotes ? { description: pointExpiryNotes } : {}
      ),
      displayTimeLabel: activeAtLabel,
      displayTime,
      isTransferable,
      state,
      stateLabel: hideLoyaltyPoints ? '' : (
        t('screens.account.myCoupons.transactionPoint', {
          context: pointSign,
          points: Math.abs(points),
          basePoints,
          unspentPoints,
        })
      ),
    }
  }

  useEffect(() => {
    if (!_.isEmpty(loyaltyPointHistory)) {
      const monthsAfterOrderred = orderMonths(loyaltyPointHistory, 'activeAt')
      setPointsOrdByMonths(monthsAfterOrderred)
    }
  }, [loyaltyPointHistory])

  // useEffect(() => {
  //   console.log('///// loyaltyPointExpiry ....', loyaltyPointExpiry)
  //   if (!_.isEmpty(loyaltyPointExpiry)) {
  //     const couponsByMonth = orderMonths(loyaltyPointExpiry, 'expireAt')
  //     setPointsExpiryOrdByMonths(couponsByMonth)
  //   }
  // }, [loyaltyPointExpiry])

  return (
    <Tabs className={style.tabContainer}>
      <TabList className={style.couponsTabs}>
        <Tab className={style.couponsTab}>{t('screens.account.loyaltyPoints.expiry')}</Tab>
        <Tab className={style.couponsTab}>{t('screens.account.loyaltyPoints.history')}</Tab>
      </TabList>
      <TabPanel>
        {
          isLoyaltyPointExpiryReady
            ? (
              <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={onFetchLoyaltyPointExpiryNextPage}
                hasMore={hasMorLoyaltyPointExpiry}
                loader={<Spinner />}
                useWindow={true}
              >
                {
                  pointsExpiryOrdByMonths.length > 0
                    ? (
                      _.map(pointsExpiryOrdByMonths, ({ title, data: items }) => (
                        <CouponMonthBlock
                          key={title}
                          sectionHeaderText={title}
                        >
                          {
                            _.map(items, (data) => {
                              const {
                                id,
                                points,
                                unspentPoints,
                                expireAt,
                              } = data
                              return (
                                <div key={`expiry_points_${id}`} className={style.sectionItem}>
                                  <div className={style.sectionItemLeft}>
                                    {
                                      _.isNil(expireAt) ? (
                                        <p>{t('screens.account.loyaltyPoints.item.labelNoExpiryDate')}</p>
                                      ) : (
                                        <>
                                          <p className={style.sectionItemLabel}>{t('screens.account.loyaltyPoints.item.label')}</p>
                                          <p className={style.sectionItemDate}>{moment(expireAt).format('L LT')}</p>
                                        </>
                                      )
                                    }
                                  </div>
                                  {
                                    !hideLoyaltyPoints && (
                                      <div className={style.sectionItemRight}>
                                        <NumberFormat
                                          displayType="text"
                                          type="text"
                                          isNumericString={true}
                                          value={parseLoyaltyPoints(unspentPoints || points)}
                                          suffix={t('screens.account.loyaltyPoints.item.pointUnit')}
                                        />
                                      </div>
                                    )
                                  }
                                </div>
                              )
                            })
                          }
                        </CouponMonthBlock>
                      ))
                    )
                    : (
                      <div className={style.noContent}>
                        <MessageScreen
                          message={t('screens.account.coupons.messageCoupons')}
                          button={{
                            text: t('screens.account.coupons.button'),
                            navigate: '/',
                          }}
                        />
                      </div>
                    )
                }
              </InfiniteScroll>
            )
            : null
        }

      </TabPanel>
      <TabPanel className={style.couponsPanel}>
        {
          isLoyaltyPointHistoryReady
            ? (
              <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={onFetchLoyaltyPointHistoryNextPage}
                hasMore={hasMorLoyaltyPointHistory}
                loader={<Spinner />}
                useWindow={true}
              >
                {
                  pointsOrdByMonths.length > 0 ? (
                    pointsOrdByMonths.map((group) => {
                      const sectionHeaderText = moment(group.month).format(dateFormat)
                      return (
                        <CouponMonthBlock
                          key={group.month}
                          sectionHeaderText={sectionHeaderText}
                        >
                          {
                            group.options.map((data) => {
                              const {
                                title,
                                description,
                                displayTime,
                                isTransferable,
                                state,
                                stateLabel,
                              } = getLoyaltyPointToItemData(data)
                              return (
                                <CouponCard
                                  key={`loyalty-point-transaction-${data.id}`}
                                  type="point"
                                  id={data.id}
                                  title={title}
                                  description={description}
                                  displayTime={displayTime}
                                  state={state}
                                  stateLabel={stateLabel}
                                  isTransferable={isTransferable}
                                />
                              )
                            })
                          }
                        </CouponMonthBlock>
                      )
                    })
                  )
                    : (
                      <div className={style.noContent}>
                        <MessageScreen
                          message={t('screens.account.coupons.message')}
                          button={{
                            text: t('screens.account.coupons.button'),
                            navigate: '/',
                          }}
                        />
                      </div>
                    )
                }
              </InfiniteScroll>
            )
            : null
        }
      </TabPanel>
    </Tabs>
  )
}

export default CouponsTabs
