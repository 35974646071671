/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import moment from 'moment/min/moment-with-locales'
import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import useStyles from './account-loyalty-points-style'
import Tabs from './tabs/tabs-view'
import { Button, Layout, SEO } from '../../../ui'
import { parseLoyaltyPoints } from '../../../helpers'

function AccountLoyaltyPointsView(props) {
  const {
    seoTitle,
    onCouponMarketplaceOpen,
    loyaltyPointExpiry,
    totalLoyaltyPoints,
    ...others
  } = props
  const tabsPorps = {
    loyaltyPointExpiry,
    ...others,
  }
  const styles = useStyles()
  const { t } = useTranslation()

  const firstRecord = useMemo(() => {
    const firstDate = _.get(loyaltyPointExpiry, '0.expireAt')
    const firstPoints = parseLoyaltyPoints(_.get(loyaltyPointExpiry, '0.unspentPoints') || _.get(loyaltyPointExpiry, '0.points'))
    if (_.isNil(firstDate)) return null
    return {
      expiryDate: moment(firstDate).format('L'),
      points: firstPoints,
    }
  }, [loyaltyPointExpiry])

  return (
    <Layout login>
      <SEO title={seoTitle} />
      <div className={styles.containerFluid}>
        <div className={styles.container}>
          <div className={styles.boxButton}>
            <div className={styles.content}>
              <p className={styles.totalPoints}>
                <Trans
                  i18nKey="screens.account.loyaltyPoints.totalPoints"
                  values={{ points: parseLoyaltyPoints(totalLoyaltyPoints) }}
                >
                  {'Total Points: '}
                  <em>2000</em>
                  {' pts'}
                </Trans>
              </p>
              {
                !_.isNil(firstRecord) && (
                  <p>
                    {t('screens.account.loyaltyPoints.pointsExpiryNote', firstRecord)}
                  </p>
                )
              }
            </div>
            <div>
              <Button
                text={t('screens.account.loyaltyPoints.ctaButton')}
                dark
                onClick={onCouponMarketplaceOpen}
                className={styles.button}
              />
            </div>
          </div>
          <Tabs {...tabsPorps} />
        </div>
      </div>
    </Layout>
  )
}

export default AccountLoyaltyPointsView
