/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState, useEffect } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  cancelRequest,
  useLoyaltyPointTransactions,
  useSystemSettings,
  useUser,
} from 'react-omnitech-api'
import { useCouponMarketplace, useThemeConfig } from '../../../hook'
import { withAuthenticableNavigationBlock } from '../../../ui'
import AccountLoyaltyPointsView from './account-loyalty-points-view'

function AccountLoyaltyPointsController() {
  // prepare hook
  const { loyaltyInformation } = useUser()
  const { t } = useTranslation()
  const { getSystemSetting } = useSystemSettings()
  const { fetchLoyaltyPointTransactions } = useLoyaltyPointTransactions()
  const { onCouponMarketplaceOpen } = useCouponMarketplace()
  const { getConfig } = useThemeConfig()
  const hideLoyaltyPoints = getConfig('config.hideLoyaltyPoints', false)

  // state
  const [loyaltyPointHistory, setLoyaltyPointHistory] = useState([])
  const [hasMorLoyaltyPointHistory, setHasMorLoyaltyPointHistory] = useState(false)
  const [fetchNextLoyaltyPointHistory, setFetchNextLoyaltyPointHistory] = useState(null)
  const [isLoyaltyPointHistoryReady, setIsLoyaltyPointHistoryReady] = useState(false)
  const [loyaltyPointExpiry, setLoyaltyPointExpiry] = useState([])
  const [hasMorLoyaltyPointExpiry, setHasMorLoyaltyPointExpiry] = useState(false)
  const [fetchNextLoyaltyPointExpiry, setFetchNextLoyaltyPointExpiry] = useState(null)
  const [isLoyaltyPointExpiryReady, setIsLoyaltyPointExpiryReady] = useState(false)
  const [pageReady, setPageReady] = useState(false)

  // local variable
  const seoTitle = t('screens.accountLoyaltyPoints.seo.title')

  const handleFetchLoyaltyPointHistory = useCallback(async (fetchNextPage) => {
    try {
      const apiQuery = getSystemSetting('api.v2.loyalty_point_transactions.index.history.ecom.query', {})
      // api call option
      const options = {
        includes: [
          'coupon_token',
          'coupon_tokens.coupon',
        ].join(','),
        sortBy: 'active_at_desc', // default show latest points transactions
        pageSize: 5,
        ...apiQuery,
      }
      // call api
      const { loyaltyPointTransactions: data, next } = _.isFunction(fetchNextPage)
        ? await fetchNextPage()
        : await fetchLoyaltyPointTransactions(options)

      // update state
      setLoyaltyPointHistory((prevState) => prevState.concat(data))
      setHasMorLoyaltyPointHistory(_.isFunction(next))
      setFetchNextLoyaltyPointHistory(() => next)
      if (_.isEmpty(fetchNextPage)) {
        setIsLoyaltyPointHistoryReady(true)
      }
    } catch (error) {
      // TODO: when fail to load coupons point, what should be shown?
    }
  }, [fetchLoyaltyPointTransactions])

  function handleFetchLoyaltyPointHistoryNextPage() {
    if (!_.isFunction(fetchNextLoyaltyPointHistory)) return

    handleFetchLoyaltyPointHistory(fetchNextLoyaltyPointHistory)
  }

  const handleFetchLoyaltyPointExpiry = useCallback(async (fetchNextPage) => {
    try {
      const apiQuery = getSystemSetting('api.v2.loyalty_point_transactions.index.expiry.ecom.query', {})
      // api call option
      const options = {
        includes: [
          'coupon_token',
          'coupon_tokens.coupon',
        ].join(','),
        scopes: [
          // 'positive',
          // 'negative',
          // 'will_expire', // Unexpired points except Points that never expire
          // 'unexpired', // All unexpired points
          'unspent',
        ],
        sortBy: 'expire_at_dsec',
        pageSize: 5,
        ...apiQuery,
      }
      // call api
      const { loyaltyPointTransactions: data, next } = _.isFunction(fetchNextPage)
        ? await fetchNextPage()
        : await fetchLoyaltyPointTransactions(options)

      // update state
      setLoyaltyPointExpiry((prevState) => prevState.concat(data))
      setHasMorLoyaltyPointExpiry(_.isFunction(next))
      setFetchNextLoyaltyPointExpiry(() => next)
      if (_.isEmpty(fetchNextPage)) {
        setIsLoyaltyPointExpiryReady(true)
      }
    } catch (error) {
      // TODO: when fail to load coupons point, what should be shown?
    } finally {
      setPageReady(true)
    }
  }, [fetchLoyaltyPointTransactions])

  function handleFetchLoyaltyPointExpiryNextPage() {
    if (!_.isFunction(fetchNextLoyaltyPointExpiry)) return

    handleFetchLoyaltyPointExpiry(fetchNextLoyaltyPointHistory)
  }

  useEffect(() => {
    handleFetchLoyaltyPointHistory() // history
    return () => {
      cancelRequest.cancelAll(['fetchLoyaltyPointTransactions'])
    }
  }, [handleFetchLoyaltyPointHistory])
  useEffect(() => {
    // fetch expiry
    handleFetchLoyaltyPointExpiry()
    return () => {
      cancelRequest.cancelAll(['fetchLoyaltyPointTransactions'])
    }
  }, [handleFetchLoyaltyPointExpiry])

  const viewProps = {
    loyaltyPointHistory,
    hasMorLoyaltyPointHistory,
    hideLoyaltyPoints,
    isLoyaltyPointHistoryReady,
    loyaltyPointExpiry,
    hasMorLoyaltyPointExpiry,
    isLoyaltyPointExpiryReady,
    totalLoyaltyPoints: _.get(loyaltyInformation, 'totalLoyaltyPoints'),
    pageReady,
    seoTitle,
    onFetchLoyaltyPointHistoryNextPage: handleFetchLoyaltyPointHistoryNextPage,
    onFetchLoyaltyPointExpiryNextPage: handleFetchLoyaltyPointExpiryNextPage,
    onCouponMarketplaceOpen,
  }

  return (
    <AccountLoyaltyPointsView {...viewProps} />
  )
}

export default withAuthenticableNavigationBlock(AccountLoyaltyPointsController)
