import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    padding: [[26, 0, 30]],
    width: '100%',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      padding: [[60, 10]],
      textAlign: 'center',
    },
  },
  container: {
    textAlign: 'center',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      width: 1170,
      minHeight: '60rem',
      margin: [[0, 'auto']],
      display: 'flex',
      justifyContent: 'start',
      flexDirection: 'column',
    },
  },
  totalPoints: {
    textAlign: 'left',
    marginBottom: '1.5rem',
    '& em': {
      fontSize: '2rem',
      fontWeight: 600,
    },
  },
  boxButton: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '0.4rem',
    marginBottom: '6rem',
    alignItems: 'flex-start',
    padding: '0 2rem',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: '6.5rem',
      marginBottom: '5rem',
      alignItems: 'center',
    },
  },
  content: {
    paddingBottom: '3rem',
    fontSize: '1.6rem',
    // '& p': {
    //   fontSize: '1.6rem',
    //   margin: 0,
    //   textAlign: 'left',
    //   lineHeight: '2rem',
    //   '&:first-child': {
    //     fontWeight: 600,
    //   },
    //   '&:last-child': {
    //     color: theme.colors.bgMediumDark,
    //   },
    // },
  },
  button: {
    minWidth: '23rem',
  },
}))

export default useStyles
