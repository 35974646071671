import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  tabContainer: {
    textAlign: 'center',
    width: '100%',
    margin: ' 0 auto',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      maxWidth: '57rem',
    },
  },
  couponsTabs: {
    display: 'flex',
    justifyContent: 'space-around',
    listStyle: 'none',
    margin: [0, '1.687rem'],
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      marginTop: '2.4rem',
    },
  },
  couponsTab: {
    position: 'relative',
    fontSize: '1.6rem',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: theme.colors.disabled,
    cursor: 'pointer',
    '&.react-tabs__tab--selected': {
      color: theme.colors.primary,
      '&:after': {
        content: '""',
        position: 'absolute',
        height: 3,
        width: '100%',
        backgroundColor: theme.colors.secondary,
        left: 0,
        bottom: '-12px',
      },
    },
  },
  couponsPanel: {
    marginTop: '3.2rem',
  },
  noContent: {
    marginTop: '10rem',
  },
  sectionItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: [['1.5rem', '2rem']],
    fontSize: '1.4rem',
    borderBottom: `1px solid ${theme.colors.bgMedium}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  sectionItemLabel: {
    marginBottom: 0,
    lineHeight: 1.5,
  },
  sectionItemDate: {
    color: theme.colors.bgMedium,
    lineHeight: 1.5,
  },
  sectionItemLeft: {
    textAlign: 'left',
  },
  sectionItemRight: {
    textAlign: 'right',
    color: theme.colors.alert,
  },
}))

export default useStyles
